@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css";

.center .slick-center .slick-opacity-50 {
  opacity: 1 !important;
  /* -ms-transform: scale(1.08);
  transform: scale(1.08); */
}
.cursor-disabled{
  cursor: not-allowed;
}
.slick-opacity-50 {
  opacity: 0.5;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

/* hide icon dropdown */
.dropdown-toggle::after {
  display: none;
}
.dropdown-toggle::before {
  display: none !important;
}
.dropstart-toggle::before {
  display: none;
}

.dropdown-menu {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
}

/* hilangkan border top di mui accordion */
.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
  background-color: rgba(0, 0, 0, 0) !important;
}

.transform-180-deg {
  transform: rotate(180deg);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.recording-animation {
  height: 50px;
  width: 50px;
  animation: recording-ann 0.7s;
  position: absolute;
  animation-iteration-count: infinite;
  animation-play-state: running;
  animation-direction: alternate;
}

@keyframes recording-ann {
  from {
    height: 50px;
    width: 50px;
  }
  to {
    height: 64px;
    width: 64px;
  }
}

.tes-working{
  background: #000;
}
.animate-spin {
  animation: spin 1s linear infinite;

}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}