.animation-controls {
  display: none;
  overflow: hidden;
}
.animation-widths {
  transition: all 0.5s ease;
}

.animation-widths:hover {
  width: 218px;

  .animation-controls {
    display: block;
  }
}
