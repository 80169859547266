$neutral_50: #fdfdfe;
$neutral_100: #d4d7db;
$neutral_200: #aaafb7;
$neutral_300: #7f8894;
$neutral_400: #556070;
$neutral_500: #2a384c;
$neutral_600: #202a39;
$neutral_700: #151c26;
$neutral_800: #0b0e13;
$neutral_900: #040608;
$neutral_1000: #000000;

$primary_100: #fff9ed;
$primary_200: #fee5b5;
$primary_300: #fed991;
$primary_400: #fdcc6c;
$primary_500: #fdbf47;

$info_100: #f4fbfe;
$info_200: #d2ecf8;
$info_300: #bbe2f5;
$info_400: #a5d9f1;
$info_500: #8ecfee;

$danger_100: #fcebed;
$danger_200: #f1aeb5;
$danger_300: #ea868f;
$danger_400: #e35d6a;
$danger_500: #dc3545;

$warning_100: #fff5e6;
$warning_200: #ffd699;
$warning_300: #ffc266;
$warning_400: #ffad33;
$warning_500: #ff9900;

$success_100: #f4fefb;
$success_200: #d2f8ed;
$success_300: #bbf5e3;
$success_400: #a5f1da;
$success_500: #8eeed1;
$success_600: #1bb184;

$secondary_100: #f8fafc;
$secondary_200: #f3f6fa;
$secondary_300: #edf2f8;
$secondary_400: #e7edf5;
$secondary_500: #e1e9f3;

$white: #ffffff;
$black: #000000;
