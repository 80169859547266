@import "../variables";
.rectangle {
  height: 1px;
  width: 100%;
  background-color: #e1e9f3;
}

.cursor-pointer {
  cursor: pointer;
}
a {
  color: $neutral_400;
  text-decoration: none;
}
a:hover {
  color: $primary_400;
  text-decoration: none;
}

.form-check-input:checked {
  background-color: $info_500 !important;
  border-color: $info_500 !important;
}
.form-check-input {
  border-color: $neutral_400 !important;
  background-color: $white !important;
}

.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}
.h-screen {
  height: 100vh;
}
.carousel-indicators button {
  border-radius: 100% !important;
}

.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
  display: block;
  height: auto;
  max-width: 100%;
  line-height: 1;
  width: 100%; // Add this
}

.bg-auth-img {
  @media (min-width: 1200px) {
    background-image: url("../../images/bg-auth-desk.png");
  }
  @media (max-width: 1199px) {
    background-image: url("../../images/bg-auth-tab.png");
  }
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.auth-wrapper {
  max-width: 438px;
  @media (min-width: "768px") {
    padding: 32px;
    background-color: white;
    border-radius: 16px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  }
}

.arranged-image {
  height: 430px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.arranged-image > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
@media (max-width: 375px) {
  .arranged-image {
    width: 100%;
    height: 310px;
  }
}
@media (min-width: 376px) {
  .arranged-image {
    width: 100%;
    height: 430px;
  }
}

.animation-control {
  display: none;
}

.animation-width:hover {
  width: 200px;
  transition: width 0.5s ease-in-out;
  .animation-control {
    display: block;
    overflow-y: hidden;
    background: none;
  }
}

.hide-scroll-bar {
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  &::-moz-scrollbar {
    display: none;
  }
}

.nowrap {
  white-space: nowrap;
}

.select-react-lib {
  &:hover {
    border-color: #fdbf47 !important;
  }
  &:active {
    border-color: #fdbf47 !important;
  }
}

.sidebar-responsive {
  width: 100% !important;
  @media (min-width: 768px) {
    width: 306px !important;
  }
}
.shadow-hover-academic:hover {
  box-shadow: 0px 4px 16px 0px #00000029;
}
.soft-shadow {
  box-shadow: 0px 4px 8px 0px #0000000d;
}
.medium-shadow {
  box-shadow: 0px 4px 16px 0px #00000029;
}

.clickable:hover{
  opacity: 0.85;
  cursor: pointer;
}