/* width */
.custom-scroll::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }
  
  /* Track */
  .custom-scroll::-webkit-scrollbar-track {
  border: 1px solid #e1e9f3;
    border-radius: 8px;
  }
   
  /* Handle */
  .custom-scroll::-webkit-scrollbar-thumb {
    background: #e1e9f3; 
    border-radius: 8px;
  }
  
  /* Handle on hover */
  .custom-scroll::-webkit-scrollbar-thumb:hover {
    background: #e1e9f3; 
  }

.tes2:focus{
  border : 1px solid red;
  outline-color: red;
}