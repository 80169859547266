@import "../variables";
@import "../typography/body";
@import "../typography/heading";
@mixin cardGeneratorX($selector: ".") {
  #{$selector}card-product-x-desk {
    // mx-16 my-24
    //font-bold p-6 promo label
    padding-bottom: 4px;
    padding-top: 4px;
    padding-left: 4px;

    width: 432px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    border-radius: 14px;
    .promo-label {
      position: absolute;
      padding: 6px;
      @include fontBold();
    }
    img {
      width: 100%;
      padding: 0;
      margin: 0;
      line-height: 0;
    }
    .card-content {
      margin: 24px 16px 24px 16px;
    }
    .card-product-img {
      line-height: 0;
    }

    .font-card-head {
      @include fontMedium();
    }
    .font-card-head-content {
      @include fontSmMedium();
    }
    .font-card-content {
      @include fontXs();
    }
    .font-card-price {
      @include headingH5();
    }
    .font-card-price-scribble {
      @include fontSm();
    }
  }
  // ======================tab x ==========
  //ml-16 mr-36 my-16
  // p-6 font-sm-bold :  promo label
  #{$selector}card-product-x-tab {
    padding-bottom: 4px;
    padding-top: 4px;
    padding-left: 4px;
    width: 420px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    border-radius: 14px;
    .promo-label {
      position: absolute;
      padding: 6px;
      @include fontSmBold();
    }
    img {
      width: 100%;
      padding: 0;
      margin: 0;
      line-height: 0;
    }
    .card-product-img {
      line-height: 0;
    }
    .card-content {
      margin: 16px 36px 16px 16px;
    }
    .font-card-head {
      @include fontSmMedium();
    }
    .font-card-head-content {
      @include fontSmMedium();
    }
    .font-card-content {
      @include fontXs();
    }
    .font-card-price {
      @include headingH6();
    }
    .font-card-price-scribble {
      @include fontXs();
    }
  }
  // =========mob x
  //  content ml-16 mr-19 my-8
  // badge py-4 px-8  font-xs-medium
  #{$selector}card-product-x-mob {
    padding-bottom: 4px;
    padding-top: 4px;
    padding-left: 4px;
    width: 313px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    border-radius: 14px;
    .promo-label {
      position: absolute;
      padding: 4px 8px;
      @include fontXsMedium();
    }
    img {
      width: 100%;
      padding: 0;
      margin: 0;
      line-height: 0;
    }
    .card-product-img {
      line-height: 0;
    }
    .card-content {
      margin: 8px 19px 8px 16px;
    }

    .font-card-head {
      @include fontXsMedium();
    }
    .font-card-head-content {
      @include fontXsBold();
    }
    .font-card-content {
      font-family: Nunito;
      font-style: normal;
      font-weight: normal;
      font-size: 9px;
      line-height: 8px;
    }
    .font-card-price {
      @include fontSmMedium();
    }
    .font-card-price-scribble {
      font-family: Nunito;
      font-style: normal;
      font-weight: normal;
      font-size: 9px;
      line-height: 16px;
    }
  }
}
// ===========================================================
@mixin cardGeneratorY($selector: ".") {
  #{$selector}card-product-y-desk {
    width: 204px;
    height: 344px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    border-radius: 14px;
    .promo-label {
      position: absolute;
      padding: 6px;
      @include fontBold();
    }
    img {
      width: 100%;
      padding: 0;
      margin: 0;
      line-height: 0;
    }
    .card-product-img {
      line-height: 0;
    }
    .font-card-head {
      @include fontMedium();
    }
    .font-card-head-content {
      @include fontSmBold();
    }
    .font-card-content {
      @include fontSm();
      color: $neutral_300;
    }
    .font-card-price {
      @include fontMedium();
    }
    .font-card-price-scribble {
      @include fontSm();
    }
    .card-content {
      margin: 8px;
    }
    .price-section {
      display: flex;
    }
  }
  //======================= y tab ==============================

  #{$selector}card-product-y-tab {
    width: 168px;
    height: 288px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    border-radius: 14px;
    padding: 4px;
    .promo-label {
      position: absolute;
      padding: 6px;
      @include fontSmBold();
    }
    img {
      width: 100%;
      padding: 0;
      margin: 0;
      line-height: 0;
    }
    .card-product-img {
      line-height: 0;
    }
    .font-card-head {
      @include fontSmMedium();
    }
    .font-card-head-content {
      @include fontSmBold();
    }
    .font-card-content {
      @include fontXs();
      color: $neutral_300;
    }
    .font-card-price {
      @include fontSmMedium();
    }
    .font-card-price-scribble {
      font-family: Nunito;
      font-style: normal;
      font-weight: normal;
      font-size: 9px;
      line-height: 16px;
    }
    .card-content {
      margin: 8px;
    }
    .price-section {
      display: flex;
    }
  }
  //======================= y mob ==============================

  #{$selector}card-product-y-mob {
    width: 140px;
    height: 268px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    border-radius: 14px;
    padding: 4px;
    .promo-label {
      position: absolute;
      padding: 5px;
      @include fontXsBold();
    }
    img {
      width: 100%;
      padding: 0;
      margin: 0;
      line-height: 0;
    }
    .card-product-img {
      line-height: 0;
    }
    .font-card-head {
      @include fontXsMedium();
    }
    .font-card-head-content {
      @include fontXsBold();
    }
    .font-card-content {
      font-family: Nunito;
      font-style: normal;
      font-weight: normal;
      font-size: 9px;
      line-height: 8px;
      color: $neutral_300;
    }
    .font-card-price {
      @include fontXsMedium();
    }
    .font-card-price-scribble {
      font-family: Nunito;
      font-style: normal;
      font-weight: normal;
      font-size: 9px;
      line-height: 16px;
    }
    .card-content {
      margin: 4px;
    }
    .price-section {
      display: block;
      line-height: 0%;
    }
  }
}

@mixin cardBookGenerator($selector: "."){
  #{$selector}card-book-sm {
    border: 1px solid $secondary_500;
    max-width: 127px;
    border-radius: 8px;
  .card-book-content{
    padding-left: 8px;
    padding-top: 8px;
    padding-right: 8px;
    .card-book-image{
      border-radius: 8px;
      width: 100%
    }
    .card-book-author{
      @include fontXs();
      color: $neutral_300;
    }
    .card-book-title{
      @include fontSmBold();
      color: $neutral_500;
      height: 40px;
    }
    .card-book-footer{
      @include fontXs();
      color: $neutral_300 ;
      padding-top: 8px;
      padding-bottom: 8px;
      display: flex;
      align-items: center;
      .card-book-progress{
        width: 100%;
        background-color: $secondary_200;
        height: 2px;
        margin-left: 8px;
        margin-right: 8px;
        border-radius: 3px;
      }
      .card-book-progress-value{
        width: 50%;
        background-color: $info_500;
        height: 2px;
        border-radius: 3px;
      }
    }
  }
    
  }

  #{$selector}card-book-xl {
     border: 1px solid $secondary_500;
     max-width: 188px;
     border-radius: 8px;
    .card-book-content{
      padding-left: 16px;
     padding-top: 16px;
     padding-right: 16px;
      .card-book-image{
        border-radius: 8px;
        width: 100%
      }
      .card-book-author{
        @include fontSm();
        color: $neutral_300;
      }
      .card-book-title{
        @include headingH6();
        color: $neutral_500;
        height: 48px;
      }
      .card-book-footer{
        @include fontXs();
        color: $neutral_300 ;
        padding-top: 8px;
        padding-bottom: 8px;
        display: flex;
        align-items: center;
        .card-book-progress{
          width: 100%;
          background-color: $secondary_200;
          height: 2px;
          margin-left: 8px;
          margin-right: 8px;
          border-radius: 3px;
        }
        .card-book-progress-value{
          width: 50%;
          background-color: $info_500;
          height: 2px;
          border-radius: 3px;
        }
      }
    }
     
   }
}

@mixin callCardGenerator($selector: ".") {
  @include cardGeneratorX($selector);
  @include cardGeneratorY($selector);
  @include cardBookGenerator($selector);
}
