.embla {
  position: relative;

  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.embla__viewport {
  overflow: hidden;
  width: 100%;
}

.embla__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.embla__viewport.is-dragging {
  cursor: grabbing;
}

.embla__container {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
  margin-left: -10px;
}

.embla__slide {
  position: relative;
  width: 100%;
  padding-left: 10px;
  padding-bottom: 5px;
}

.embla__slide__inner {
  position: relative;
  overflow: hidden;
  height: 190px;
  padding-bottom: 5px;
  border-radius: 14px;
}

.embla__slide__img {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  width: auto;
  min-height: 100%;
  min-width: 100%;
  max-width: none;
  transform: translate(-50%, -50%);
}

.embla__button {
  outline: 0;
  cursor: pointer;
  /* background-color: #e7edf5; */
  touch-action: manipulation;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  fill: #fdbf47;
  padding-top: 0px;
}

.embla__button:disabled {
  cursor: default;
  opacity: 0.3;
}

.embla__button__svg {
  width: 100%;
  height: 100%;
}

.embla__button--prev {
  /* left: 27px; */
  transform: rotate(180deg);
}

.embla__button--next {
  /* right: 27px; */
}
